import BackIcon from "../icons/Back";
import "./ExerciseBack.css";
import LeftArrow from "./LeftArrow";
import RightArrow from "./RightArrow";

interface ExerciseBackProps {
  currentSlide: number;
  totalSlides: number;
  title: string;
  scienceImage: string;
  science: string;
  handleClick: () => void;
  handleLeftArrow: () => void;
  handleRightArrow: () => void;
}

const ExerciseBack = ({
  currentSlide,
  totalSlides,
  title,
  scienceImage,
  science,
  handleClick,
  handleLeftArrow,
  handleRightArrow,
}: ExerciseBackProps) => {
  return (
    <>
      <div className="info">
        <h2>{title}</h2>
        <img loading="lazy" src={scienceImage} alt="The Science" />
        <div className="science">
          <h3>The Science</h3>
          <p dangerouslySetInnerHTML={{ __html: science }}></p>
        </div>
        <div className="buttons">
          <div className="button" onClick={handleClick}>
            <span>
              <BackIcon />
              &nbsp;The Exercise
            </span>
          </div>
        </div>
      </div>
      <div className="bottom">
        <LeftArrow handleLeftArrow={handleLeftArrow} />
        {currentSlide + 1}
        <span>/</span>
        {totalSlides}
        <RightArrow handleRightArrow={handleRightArrow} />
      </div>
    </>
  );
};

export default ExerciseBack;
