import "./Card.css";

interface CardProps {
  title: string;
  description: string;
  image: string;
  toggleDrawer: () => void;
}

const Card = ({ title, description, image, toggleDrawer }: CardProps) => {
  return (
    <div className="card" onClick={toggleDrawer}>
      <div className="title">
        <h3>{title}</h3>
        <p>{description}</p>
      </div>
      <img loading="lazy" src={image} alt={title} />
    </div>
  );
};

export default Card;
