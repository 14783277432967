export const mindsetData = [
  {
    title: "Clarity Raises",
    description: "Activate cortex & boost focus.",
    image: "/MindSets_Tile_ClarityRaises.png",
    exercise1Image: "/MindSets_Card_ClarityRaises1.png",
    exercise2Image: "/MindSets_Card_ClarityRaises2.png",
    exercise1: "Think of a category. For example, types of fruit.",
    exercise2: "In your head, list something for each letter of the alphabet in that category.",
    scienceImage: "/MindSets_Brain_ClarityRaises1.png",
    science:
      "<p>In between working your core, you might want to consider your cortex. This MindSets exercise uses the power of self-distraction to activate your left lateral prefrontal cortex and hippocampus.</p><p>These parts of the brain are involved in problem-solving and working memory, helping to keep the brain sharp and improve mental flexibility. But good luck finding a fruit that starts with D.</p>",
  },
  {
    title: "Calmness Raises",
    description: "Crush some stress hormones.",
    image: "/MindSets_Tile_CalmnessRaises.png",
    exercise1Image: "/MindSets_Card_CalmnessRaises1.png",
    exercise2Image: "/MindSets_Card_CalmnessRaises2.png",
    exercise1: "Tense and relax each muscle group in your body. Start at your toes.",
    exercise2: "Gradually work your way up, finishing at your head.",
    scienceImage: "/MindSets_Brain_CalmnessRaises1.png",
    science:
      "<p>Flexing in the mirror can give you a hit of dopamine, but flexing with more intention can help reduce muscle tension and lower cortisol levels which in turn decreases stress and anxiety.</p><p>It also stimulates the parasympathetic nervous system, which counteracts the body's fight-or-flight reaction. Stimulating this system aids in muscle recovery and tissue repair. So if you’re going to flex, make sure you do a full body flex-out between sets.</p>",
  },
  {
    title: "Mood Raises",
    description: "Torch your physical tension.",
    image: "/MindSets_Tile_MoodRaises.png",
    exercise1Image: "/MindSets_Card_MoodRaises1.png",
    exercise2Image: "/MindSets_Card_MoodRaises2.png",
    exercise1: "Close your eyes and mentally “scan” your body from head to toe.",
    exercise2: "Notice any areas of tension or discomfort and release them.",
    scienceImage: "/MindSets_Brain_MoodRaises1.png",
    science:
      "<p>Recovery is an important part of working out, but when was the last time you helped your mind recover? This MindSets exercise helps activate our body’s parasympathetic nervous system in a unique way that calms different structures in the limbic system, aiding mental recovery and reducing overall physical tension.</p>",
  },
  {
    title: "Focus Pull Ups",
    description: "Shred those nerves and jitters.",
    image: "/MindSets_Tile_ForecePullUps.png",
    exercise1Image: "/MindSets_Card_ForecePullUps1.png",
    exercise2Image: "/MindSets_Card_ForecePullUps2.png",
    exercise1: "",
    exercise2: "",
    scienceImage: "/MindSets_Brain_ForecePullUps1.png",
    science:
      "<p>You know the importance of spotting others, but this spotting exercise plays an important role in promoting a calm and focused state. By using your senses to connect the present moment, you are activating the insula and the anterior cingulate cortex.</p><p>Together, these brain regions help stabilise the nervous system and foster a sense of calm through mindful awareness. So avoid awkward eye contact in the gym and get spotting.</p>",
  },
  {
    title: "Mood Lifts",
    description: "Activate & oxygenate your brain.",
    image: "/MindSets_Tile_MoodLifts.png",
    exercise1Image: "/MindSets_Card_MoodLifts1.png",
    exercise2Image: "/MindSets_Card_MoodLifts2.png",
    exercise1: "Place one hand on your chest and the other on your stomach.",
    exercise2: "Perform 10 deep breaths, noticing the hand on your stomach as it lifts and lowers.",
    scienceImage: "/MindSets_Brain_MoodLifts1.png",
    science:
      "<p>In between some heavy reps, doing some breathing reps can help release any build-up of tension in the body. This MindSets exercise activates the parasympathetic nervous system, reducing the production of stress hormones like cortisol, making you feel calmer and less anxious.</p><p>It also increases oxygen flow, which can improve brain function keeping you focused and fired up for the following set.</p>",
  },
  {
    title: "Focus Inclines",
    description: "Recalibrate your nervous system.",
    image: "/MindSets_Tile_FocusInclines.png",
    exercise1Image: "/MindSets_Card_FocusInclines1.png",
    exercise2Image: "/MindSets_Card_FocusInclines2.png",
    exercise1: "Visualise a moment you’re looking forward to.",
    exercise2: "Imagine what you’ll see, hear, touch, taste, smell, and how you’ll feel.",
    scienceImage: "/MindSets_Brain_FocusInclines1.png",
    science:
      "<p>Imagining the perfect beach body can help keep you motivated, but imagining a moment you’re looking forward to can help calm your sympathetic nervous system and activate your parasympathetic nervous system.</p><p>This will lower your heart rate, reduce stress hormones, and maintain better control over your breathing, allowing you to push further without feeling the burnout.</p>",
  },
  {
    title: "Serotonin Lifts",
    description: "Pump some feel-good hormones.",
    image: "/MindSets_Tile_SerotoninLifts.png",
    exercise1Image: "/MindSets_Card_SerotoninLifts1.png",
    exercise2Image: "/MindSets_Card_SerotoninLifts2.png",
    exercise1: "",
    exercise2: "",
    scienceImage: "/MindSets_Brain_SerotoninLifts1.png",
    science:
      "<p>In between lifting weights, play your favourite song and it will lift the level of serotonin in your bloodstream. Serotonin is one of the feel-good hormones produced in the hypothalamus region of the brain, a key part of the hypothalamic-pituitary-adrenal axis.</p><p>This MindSets exercise aids mental recovery, reduces anxiety, blood pressure as well as improving your quality of sleep…and high-frequency music leads to the greatest stress relief, so in between pumping iron, pump some techno.</p>",
  },
  {
    title: "Oxytocin Press Ups",
    description: "Ignite your brain's reward circuitry.",
    image: "/MindSets_Tile_OxytocinPressUps.png",
    exercise1Image: "/MindSets_Card_OxytocinPressUps1.png",
    exercise2Image: "/MindSets_Card_OxytocinPressUps2.png",
    exercise1: "Think of a mate and send them a message.",
    exercise2: "",
    scienceImage: "/MindSets_Brain_OxytocinPressUps1.png",
    science:
      "<p>A buddy can help motivate you, even if they’re not a gym buddy. Thinking about friends activates specific areas of the brain more than other types of relationships. Particularly the ventromedial prefrontal cortex and caudate nucleus, which are part of the brain's reward circuitry.</p><p>These areas of the brain process the effects of oxytocin and help increase motivation, so you don’t always need to have someone yelling at you to ‘dig deep’.</p>",
  },
  {
    title: "Oxytocin Pull Ups",
    description: "A quick hit of happy hormones.",
    image: "/MindSets_Tile_OxytocinPullUps.png",
    exercise1Image: "/MindSets_Card_OxytocinPullUps1.png",
    exercise2Image: "/MindSets_Card_OxytocinPullUps2.png",
    exercise1: "Pull up a photo of someone special.",
    exercise2: "Spend a moment thinking about what they mean to you.",
    scienceImage: "/MindSets_Brain_OxytocinPullUps1.png",
    science:
      "<p>You get a hit of dopamine when you check yourself out in the mirror. But looking at a photo of someone special and reflecting on that relationship increases oxytocin and serotonin levels, improving mood and reducing stress.</p><p>This MindSets exercise also increases activity in the caudate nucleus, lateral orbitofrontal cortex, amygdala, and dorsolateral prefrontal cortex, which are reward-processing regions of the brain. So you can thank that person next time you see them…or give them a belly rub.</p>",
  },
  {
    title: "Wellbeing Lifts",
    description: "Lift compassion. Lower stress.",
    image: "/MindSets_Tile_WellbeingLifts.png",
    exercise1Image: "/MindSets_Card_WellbeingLifts1.png",
    exercise2Image: "/MindSets_Card_WellbeingLifts2.png",
    exercise1: "In your head, say the following words: “I’m fit, I’m focused, I’ll face anything”.",
    exercise2: "Repeat it for a friend. Then a foe:“They’re fit, they’re focused, they’ll face anything”.",
    scienceImage: "/MindSets_Brain_WellbeingLifts1.png",
    science:
      "<p>Yelling can help activate core muscles to stabilise the body for a stronger lift, but by yelling a positive mantra in your mind can help lift your compassion and lower stress.</p><p>This MindSets exercise increases mindful connection, increases vagal tone and leads to an upward spiral of well-being, improving your quality of life and life satisfaction. So, rather than upsetting your gym neighbours, yell some mind-mantras between sets.</p>",
  },
];
