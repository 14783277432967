import Footer from '../components/Footer';
import Header from '../components/Header';
import "./About.css";

function About() {
  return (
    <div className="about">
      <Header />
      <div className="other-image"></div>
      <div className="page">
        {/* <h1>About</h1>
        <h3>Over Title</h3>
        <h2>Standard title</h2> */}
        <p>In every gym there’s a lot of downtime between sets.</p>
        <p>We figured that time could be used to keep your most vital body part healthy - your brain.</p>
        <p>So, we’ve created MindSets.</p>
        <p>It’s a program consisting of 10 simple, mind-building exercises that are custom designed to be done during your rest intervals. They’ve been developed by Movember, Life Fitness, and leading psychologist, Jacqui Maguire.</p>
        <p>Try a couple next time you’re working out. And if you know someone who could gain from MindSets, be a good gym buddy and share it with them.</p>
      </div>
      <Footer />
    </div>
  )
}

export default About;
