import { Route, BrowserRouter as Router, Switch } from "react-router-dom";
import "./App.css";
import Sidebar from "./components/Sidebar";
import About from "./pages/About";
import Contact from "./pages/Contact";
import Gyms from "./pages/Gyms";
import Home from "./pages/Home";

function App() {
  return (
    <div className="App">
      <Sidebar pageWrapId="page-wrap" outerContainerId="App" />
      <div id="page-wrap">
        <Router>
          <Switch>
            <Route path="/about">
              <About />
            </Route>
            <Route path="/contact">
              <Contact />
            </Route>
            <Route path="/gyms">
              <Gyms />
            </Route>
            <Route
              path="/geninf"
              component={() => {
                window.location.href = "https://movember.mindsets.co.nz/geninf";
                return null;
              }}
            />
            <Route
              path="/fitinf"
              component={() => {
                window.location.href = "https://movember.mindsets.co.nz/fitinf";
                return null;
              }}
            />
            <Route path="/">
              <Home />
            </Route>
          </Switch>
        </Router>
      </div>
    </div>
  );
}

export default App;
