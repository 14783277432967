import LeftIcon from "../icons/Left";
import "./LeftArrow.css";

const LeftArrow = (props: any) => {
  return (
    <div className="slick-arrow slick-prev" {...props} onClick={props.handleLeftArrow}>
      <div className="arrow-wrapper">
        <LeftIcon />
      </div>
    </div>
  );
};

export default LeftArrow;
