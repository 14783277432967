const ExternalBlackIcon = (props: any) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="17"
      height="16"
      viewBox="0 0 17 16"
      fill="none"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.71404 13.714C2.4537 13.4537 2.4537 13.0316 2.71404 12.7712L13.0849 2.40034C13.3453 2.13999 13.7674 2.13999 14.0278 2.40034C14.2881 2.66069 14.2881 3.0828 14.0278 3.34315L3.65685 13.714C3.3965 13.9744 2.97439 13.9744 2.71404 13.714Z"
        fill="black"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.4044 2.87174C4.4044 2.50355 4.70288 2.20507 5.07107 2.20507L13.5563 2.20507C13.9245 2.20507 14.223 2.50355 14.223 2.87174L14.223 11.357C14.223 11.7252 13.9245 12.0237 13.5563 12.0237C13.1882 12.0237 12.8897 11.7252 12.8897 11.357L12.8897 3.53841L5.07107 3.53841C4.70288 3.53841 4.4044 3.23993 4.4044 2.87174Z"
        fill="black"
      />
    </svg>
  );
};

export default ExternalBlackIcon;
