import React from "react";
import ReactCardFlip from "react-card-flip";
import "./Exercise.css";
import ExerciseBack from "./ExerciseBack";
import ExerciseFront from "./ExerciseFront";

interface ExerciseProps {
  currentSlide: number;
  totalSlides: number;
  title: string;
  exercise1Image: string;
  exercise2Image: string;
  exercise1: string;
  exercise2: string;
  scienceImage: string;
  science: string;
  handleLeftArrow: () => void;
  handleRightArrow: () => void;
}

const Exercise = ({
  currentSlide,
  totalSlides,
  title,
  exercise1Image,
  exercise2Image,
  exercise1,
  exercise2,
  scienceImage,
  science,
  handleLeftArrow,
  handleRightArrow,
}: ExerciseProps) => {
  const [isFlipped, setIsFlipped] = React.useState(false);

  const handleClick = () => {
    setIsFlipped(!isFlipped);
  };

  return (
    <div className="exercise">
      <div>
        <ReactCardFlip isFlipped={isFlipped}>
          <ExerciseFront
            currentSlide={currentSlide}
            totalSlides={totalSlides}
            title={title}
            exercise1={exercise1}
            exercise2={exercise2}
            exercise1Image={exercise1Image}
            exercise2Image={exercise2Image}
            handleClick={handleClick}
            handleLeftArrow={handleLeftArrow}
            handleRightArrow={handleRightArrow}
          />
          <ExerciseBack
            currentSlide={currentSlide}
            totalSlides={totalSlides}
            title={title}
            science={science}
            scienceImage={scienceImage}
            handleClick={handleClick}
            handleLeftArrow={handleLeftArrow}
            handleRightArrow={handleRightArrow}
          />
        </ReactCardFlip>
      </div>
    </div>
  );
};

export default Exercise;
