const LeftIcon = (props: any) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="17"
    height="17"
    viewBox="0 0 17 17"
    fill="none"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16.2109 8.5C16.2109 8.83137 15.9125 9.1 15.5443 9.1L0.877603 9.1C0.509413 9.1 0.210936 8.83137 0.210936 8.5C0.210936 8.16863 0.509413 7.9 0.877603 7.9L15.5443 7.9C15.9125 7.9 16.2109 8.16863 16.2109 8.5Z"
      fill="#D9D9D9"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.34901 14.3243C7.08866 14.5586 6.66655 14.5586 6.4062 14.3243L0.406198 8.92426C0.145848 8.68995 0.145848 8.31005 0.406198 8.07574L6.4062 2.67574C6.66655 2.44142 7.08866 2.44142 7.34901 2.67574C7.60936 2.91005 7.60936 3.28995 7.34901 3.52426L1.82041 8.5L7.34901 13.4757C7.60936 13.71 7.60936 14.0899 7.34901 14.3243Z"
      fill="#D9D9D9"
    />
  </svg>
);

export default LeftIcon;
