const BurgerWhiteIcon = (props: any) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="18"
    viewBox="0 0 24 18"
    fill="none"
    {...props}
  >
    <line
      x1="1"
      y1="1"
      x2="23"
      y2="0.999998"
      stroke="#F7F7F7"
      strokeWidth="2"
      strokeLinecap="round"
    />
    <line
      x1="1"
      y1="8.52942"
      x2="23"
      y2="8.52942"
      stroke="#F7F7F7"
      strokeWidth="2"
      strokeLinecap="round"
    />
    <line
      x1="1"
      y1="17"
      x2="23"
      y2="17"
      stroke="#F7F7F7"
      strokeWidth="2"
      strokeLinecap="round"
    />
  </svg>
);

export default BurgerWhiteIcon;
