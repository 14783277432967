const BackIcon = (props: any) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="13"
    viewBox="0 0 16 13"
    fill="none"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16 6.49999C16 6.85346 15.7015 7.13999 15.3333 7.13999L0.666666 7.13999C0.298477 7.13999 0 6.85346 0 6.49999C0 6.14653 0.298477 5.85999 0.666666 5.85999L15.3333 5.85999C15.7015 5.85999 16 6.14653 16 6.49999Z"
      fill="#F7F7F7"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.13807 12.7125C6.87772 12.9625 6.45561 12.9625 6.19526 12.7125L0.195261 6.95254C-0.0650883 6.70261 -0.0650883 6.29738 0.195261 6.04745L6.19526 0.287445C6.45561 0.0375099 6.87772 0.0375099 7.13807 0.287445C7.39842 0.537382 7.39842 0.942607 7.13807 1.19254L1.60948 6.49999L7.13807 11.8074C7.39842 12.0574 7.39842 12.4626 7.13807 12.7125Z"
      fill="#F7F7F7"
    />
  </svg>
);

export default BackIcon;
