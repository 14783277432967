import { Link } from "react-router-dom";
import BurgerBlackIcon from "../icons/BurgerBlack";
import MoBlackIcon from "../icons/MoBlack";
import "./Header.css";

const Header = () => {
  return (
    <div className="header">
      <Link to="/">
        <img src="logo-black.png" alt="Movember MindSets" className="medium" />
        <div className="mo">
          <MoBlackIcon />
        </div>
      </Link>
      <div className="nav">
        <Link to="/about">About</Link>
        <Link to="/contact">Contact</Link>
        <Link to="/gyms">Gyms</Link>
      </div>
      <div className="burger">
        <BurgerBlackIcon />
      </div>
    </div>
  );
};

export default Header;
