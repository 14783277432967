const MoWhiteIcon = (props: any) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={49}
    height={12}
    fill="none"
    {...props}
  >
    <path
      fill="#F7F7F7"
      d="M32.477 10.765c-.804 0-1.61.012-2.413-.007a2.31 2.31 0 0 1-.752-.152 410.478 410.478 0 0 1-4.605-1.792.787.787 0 0 0-.629-.003c-1.505.595-3.01 1.188-4.525 1.761a2.994 2.994 0 0 1-.945.189c-1.456.044-2.918-.009-4.366.109-1.283.103-2.553.375-3.825.591-.896.153-1.783.355-2.681.493a1.91 1.91 0 0 1-.92-.082C4.667 11.058 2.53 10.217.39 9.383c-.4-.156-.439-.207-.354-.626.535-2.645 1.076-5.288 1.62-7.93.035-.172.078-.346.154-.503.04-.082.158-.129.24-.191.062.081.168.157.179.244.07.568.113 1.139.179 1.707.108.943.22 1.886.347 2.827.055.418.262.755.635 1.006.353.237.679.512 1.017.77.504.383.912.397 1.44.041C7.87 5.361 9.89 3.989 11.916 2.625 13.294 1.697 14.717.842 16.331.34c2.233-.694 4.295-.303 6.192.975.501.338.949.749 1.438 1.105.483.352.516.35.998.004.353-.253.683-.536 1.028-.8 2.135-1.637 4.48-2.072 7.06-1.13 1.648.6 3.094 1.538 4.52 2.508 1.84 1.251 3.684 2.494 5.528 3.738.51.344.924.326 1.416-.049.376-.286.743-.582 1.127-.859.26-.187.426-.425.479-.727.1-.581.208-1.162.276-1.747.113-.98.192-1.965.299-2.946.01-.098.114-.186.174-.278.09.077.24.141.263.235.201.788.399 1.578.56 2.374.413 2.018.808 4.04 1.207 6.061.07.353.02.43-.33.566-2.13.83-4.263 1.655-6.39 2.493-.383.151-.764.168-1.154.095-1.75-.327-3.49-.703-5.248-.975-.901-.139-1.832-.096-2.75-.133-.182-.007-.364 0-.546 0v-.085Z"
    />
  </svg>
);

export default MoWhiteIcon;
