const ForwardIcon = (props: any) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="14"
    viewBox="0 0 16 14"
    fill="none"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0 7C0 6.64654 0.298477 6.36 0.666667 6.36L15.3333 6.36C15.7015 6.36 16 6.64654 16 7C16 7.35346 15.7015 7.64 15.3333 7.64L0.666667 7.64C0.298477 7.64 0 7.35346 0 7Z"
      fill="#F7F7F7"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.86193 0.78745C9.12228 0.537515 9.54439 0.537515 9.80474 0.78745L15.8047 6.54745C16.0651 6.79739 16.0651 7.20261 15.8047 7.45255L9.80474 13.2125C9.54439 13.4625 9.12228 13.4625 8.86193 13.2125C8.60158 12.9626 8.60158 12.5574 8.86193 12.3074L14.3905 7L8.86193 1.69255C8.60158 1.44261 8.60158 1.03739 8.86193 0.78745Z"
      fill="#F7F7F7"
    />
  </svg>
);

export default ForwardIcon;
