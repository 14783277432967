// import AudioIcon from "../icons/Audio";
import ForwardIcon from "../icons/Forward";
import "./ExerciseFront.css";
import LeftArrow from "./LeftArrow";
import RightArrow from "./RightArrow";

interface ExerciseFrontProps {
  currentSlide: number;
  totalSlides: number;
  title: string;
  exercise1Image: string;
  exercise2Image: string;
  exercise1: string;
  exercise2: string;
  handleClick: () => void;
  handleLeftArrow: () => void;
  handleRightArrow: () => void;
}

const ExerciseFront = ({
  currentSlide,
  totalSlides,
  title,
  exercise1Image,
  exercise2Image,
  exercise1,
  exercise2,
  handleClick,
  handleLeftArrow,
  handleRightArrow,
}: ExerciseFrontProps) => {
  return (
    <>
      <div className="info">
        <h2>{title}</h2>
        <div className="box">
          <img loading="lazy" src={exercise1Image} alt={exercise1} className={`${exercise1 === "" ? "empty" : ""}`} />
          {exercise1 !== "" && <p>{exercise1}</p>}
        </div>
        <div className="box">
          <img loading="lazy" src={exercise2Image} alt={exercise2} className={`${exercise2 === "" ? "empty" : ""}`} />
          {exercise2 !== "" && <p>{exercise2}</p>}
        </div>
        <div className="buttons">
          {/* <div className="button">
          <span>
            Play&nbsp;
            <AudioIcon />
          </span>
        </div> */}
          <div className="button" onClick={handleClick}>
            <span>
              The Science&nbsp;
              <ForwardIcon />
            </span>
          </div>
        </div>
      </div>
      <div className="bottom">
        <LeftArrow handleLeftArrow={handleLeftArrow} />
        {currentSlide + 1}
        <span>/</span>
        {totalSlides}
        <RightArrow handleRightArrow={handleRightArrow} />
      </div>
    </>
  );
};

export default ExerciseFront;
