const ExternalWhiteIcon = (props: any) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.51443 20.4853C3.1239 20.0947 3.1239 19.4616 3.51443 19.071L19.0708 3.51469C19.4613 3.12417 20.0945 3.12417 20.485 3.51469C20.8755 3.90521 20.8755 4.53838 20.485 4.9289L4.92864 20.4853C4.53812 20.8758 3.90495 20.8758 3.51443 20.4853Z"
        fill="#F7F7F7"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.04996 4.2218C6.04996 3.66951 6.49768 3.2218 7.04996 3.2218L19.7779 3.2218C20.3302 3.22179 20.7779 3.66951 20.7779 4.2218V16.9497C20.7779 17.502 20.3302 17.9497 19.7779 17.9497C19.2256 17.9497 18.7779 17.502 18.7779 16.9497L18.7779 5.2218L7.04996 5.2218C6.49768 5.2218 6.04996 4.77408 6.04996 4.2218Z"
        fill="#F7F7F7"
      />
    </svg>
  );
};

export default ExternalWhiteIcon;
