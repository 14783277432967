import { slide as Menu } from "react-burger-menu";
import MoBlackIcon from "../icons/MoBlack";
import "./Sidebar.css";

const Sidebar = (props: any) => {
  return (
    <Menu right {...props}>
      <div className="top">
        <a className="menu-item" href="/">
          <MoBlackIcon />
        </a>
      </div>
      <div className="middle">
        <a className="menu-item" href="/about">
          About
        </a>
        <a className="menu-item" href="/contact">
          Contact
        </a>
        <a className="menu-item" href="/gyms">
          Gyms
        </a>
      </div>
    </Menu>
  );
};

export default Sidebar;
