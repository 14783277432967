import React from "react";
import Drawer from "react-modern-drawer";
import "react-modern-drawer/dist/index.css";
import { Link } from "react-router-dom";
import Slider from "react-slick";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import Card from "../components/Card";
import Exercise from "../components/Exercise";
import ExerciseBack from "../components/ExerciseBack";
import ExerciseFront from "../components/ExerciseFront";
import Footer from "../components/Footer";
// import LeftArrow from "../components/LeftArrow";
// import RightArrow from "../components/RightArrow";
import { mindsetData } from "../data/exercises";
import useWindowDimensions from "../hooks/useWindowDimensions";
import BackIcon from "../icons/Back";
import BurgerWhiteIcon from "../icons/BurgerWhite";
import ExternalWhiteIcon from "../icons/ExternalWhite";
import MoWhiteIcon from "../icons/MoWhite";
import "./Home.css";
import DownIcon from "../icons/Down";
import ExternalBlackIcon from "../icons/ExternalBlack";

const sliderSettings = {
  dots: false,
  infinite: false,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  arrows: false,
  // prevArrow: <LeftArrow />,
  // nextArrow: <RightArrow />,
};

function Home() {
  let sliderRef = React.useRef<any>(null);
  const { width } = useWindowDimensions();

  const [isOpen, setIsOpen] = React.useState(false);
  const [selectedCard, setSelectedCard] = React.useState(0);
  const [currentSlide, setCurrentSlide] = React.useState(0);

  const toggleDrawer = () => {
    if (width < 768) {
      setIsOpen((prevState) => !prevState);
    }
  };

  const handleCardClick = (index: number) => {
    setSelectedCard(index);
    sliderRef.current?.slickGoTo(index);
  };

  const handleLeftArrow = () => {
    sliderRef.current?.slickPrev();
  };

  const handleRightArrow = () => {
    sliderRef.current?.slickNext();
  };

  const handleAfterChange = (currentSlide: number) => {
    setCurrentSlide(currentSlide);
  };

  return (
    <div>
      <section className="hero">
        <div className="header">
          <Link to="/">
            <MoWhiteIcon />
          </Link>
          <div className="nav">
            <Link to="/about">About</Link>
            <Link to="/contact">Contact</Link>
            <Link to="/gyms">Gyms</Link>
          </div>
          <div className="burger">
            <BurgerWhiteIcon />
          </div>
        </div>
        <div className="logo-container">
          <img
            loading="lazy"
            src="logo.png"
            alt="Movember MindSets"
            className="primary"
          />
        </div>
        <div className="bottom">
          <img
            loading="lazy"
            src="logo.png"
            alt="Movember MindSets"
            className="primary"
          />
          <div className="headline">
            <h5>The program designed to</h5>
            <h1>Strengthen your mind between sets</h1>
            {/* <p className="blurb">
              About this programme. Lorem ipsum. Reflecting on positive
              relationships increases oxytocin and serotonin levels, improving
              mood.
            </p> */}
          </div>
          <div className="center">
            <a href="#cards">
              <div className="down-arrow-icon bouncy">
                <DownIcon />
              </div>
            </a>
          </div>
        </div>
      </section>
      <section className="cards">
        <a id="cards"></a>
        {/* <p className="blurb">
          About this programme. Lorem ipsum. Reflecting on positive
          relationships increases oxytocin and serotonin levels, improving mood.
        </p> */}
        <h2>The Mindsets</h2>
        <div className="exercise-container">
          <div className="card-container">
            {mindsetData.map((mindset, index) => (
              <div
                className={`card-wrapper ${
                  selectedCard === index ? "selected" : ""
                }`}
                key={index}
                onClick={() => handleCardClick(index)}
              >
                <Card key={index} {...mindset} toggleDrawer={toggleDrawer} />
              </div>
            ))}
          </div>
          <div className="exercise-wrapper">
            <div className="exercise">
              <ExerciseFront
                currentSlide={currentSlide + 1}
                totalSlides={mindsetData.length}
                title={mindsetData[selectedCard].title}
                exercise1={mindsetData[selectedCard].exercise1}
                exercise2={mindsetData[selectedCard].exercise2}
                exercise1Image={mindsetData[selectedCard].exercise1Image}
                exercise2Image={mindsetData[selectedCard].exercise2Image}
                handleClick={() => {}}
                handleLeftArrow={() => {}}
                handleRightArrow={() => {}}
              />
            </div>
            <div className="exercise">
              <ExerciseBack
                currentSlide={currentSlide + 1}
                totalSlides={mindsetData.length}
                title={mindsetData[selectedCard].title}
                science={mindsetData[selectedCard].science}
                scienceImage={mindsetData[selectedCard].scienceImage}
                handleClick={() => {}}
                handleLeftArrow={() => {}}
                handleRightArrow={() => {}}
              />
            </div>
          </div>
        </div>
      </section>
      <section className="donate">
        <div className="alternative">
          <a
            href="https://www.iheart.com/podcast/1049-mindsets-239768543/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <div className="button">
              <span>
                Alternative guide to MindSets&nbsp;
                <ExternalBlackIcon />
              </span>
            </div>
          </a>
          <img src="/acc.png" alt="Alternative Commentary Collective" />
        </div>
        <p>#MovemberMindSets</p>
        <h2>
          Help Aotearoa become stronger with more initiatives like this one
        </h2>
        <a
          href="https://www.movember.com/support-us/donate"
          target="_blank"
          rel="noopener noreferrer"
        >
          <div className="button">
            <span>
              Donate to Movember&nbsp;
              <ExternalWhiteIcon />
            </span>
          </div>
        </a>
      </section>
      <Footer />
      <Drawer
        open={isOpen}
        onClose={toggleDrawer}
        direction="right"
        className=""
        size="100vw"
      >
        <div className="drawer">
          <div className="top">
            <MoWhiteIcon />
            <span onClick={toggleDrawer}>
              <BackIcon />
              &nbsp;Close
            </span>
          </div>
          <div className="content">
            <Slider
              {...sliderSettings}
              ref={sliderRef}
              afterChange={handleAfterChange}
            >
              {mindsetData.map((mindset, index) => (
                <Exercise
                  key={index}
                  currentSlide={currentSlide}
                  totalSlides={mindsetData.length}
                  {...mindset}
                  handleLeftArrow={handleLeftArrow}
                  handleRightArrow={handleRightArrow}
                />
              ))}
            </Slider>
            {/* <div className="bottom">
              {currentSlide + 1}
              <span>/</span>
              {mindsetData.length}
            </div> */}
          </div>
        </div>
      </Drawer>
    </div>
  );
}

export default Home;
