import RightIcon from "../icons/Right";
import "./RightArrow.css";

const RightArrow = (props: any) => {
  return (
    <div className="slick-arrow slick-next" {...props} onClick={props.handleRightArrow}>
      <div className="arrow-wrapper">
        <RightIcon />
      </div>
    </div>
  );
};

export default RightArrow;
