import { useEffect, useState, useRef } from "react";
import emailjs from "@emailjs/browser";
import Footer from "../components/Footer";
import Header from "../components/Header";
import "./Contact.css";

function Contact() {
  const firstnameRef = useRef<HTMLInputElement>(null);
  const lastnameRef = useRef<HTMLInputElement>(null);
  const emailRef = useRef<HTMLInputElement>(null);
  const messageRef = useRef<HTMLTextAreaElement>(null);
  const [loading, setLoading] = useState(false);
  const [emailSent, setEmailSent] = useState(false);

  useEffect(() => emailjs.init("Rhf5d7xW7RUdYldG2"), []);

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    const serviceId = "service_mindsets";
    const templateId = "template_rpmwpzm";

    try {
      setLoading(true);
      await emailjs.send(serviceId, templateId, {
        firstname: firstnameRef.current?.value,
        lastname: lastnameRef.current?.value,
        email: emailRef.current?.value,
        message: messageRef.current?.value,
      });
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
      setEmailSent(true);
    }
  };

  return (
    <div className="contact">
      <Header />
      <div className="other-image"></div>
      <div className="page">
        <h1>Contact</h1>
        {/* <h3>Over Title</h3>
        <h2>Standard title</h2> */}
        <p>
          If you’d like more details about the MindSets program, feel free to
          reach out. In the meantime... don’t skip brain day.
        </p>
        {emailSent ? (
          <div className="complete">
            <p>
              Your form has been successfully submitted. Thanks for getting in
              touch.
            </p>
          </div>
        ) : (
          <form className="form" onSubmit={handleSubmit}>
            <label htmlFor="firstName">First name</label>
            <input
              ref={firstnameRef}
              type="text"
              id="firstName"
              name="firstName"
              placeholder="First name"
            ></input>
            <label htmlFor="lastName">Last name</label>
            <input
              ref={lastnameRef}
              type="text"
              id="lastName"
              name="lastName"
              placeholder="Last name"
            ></input>
            <label htmlFor="email">Email</label>
            <input
              ref={emailRef}
              type="email"
              id="email"
              name="email"
              placeholder="Email address"
            ></input>
            <label htmlFor="message">Message</label>
            <textarea
              ref={messageRef}
              id="message"
              name="message"
              placeholder=""
              rows={5}
            ></textarea>
            <button className="button" type="submit">
              <span>Submit</span>
            </button>
          </form>
        )}
      </div>
      <Footer />
    </div>
  );
}

export default Contact;
