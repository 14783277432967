const DownIcon = (props: any) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 3C12.4142 3 12.75 3.33579 12.75 3.75L12.75 20.25C12.75 20.6642 12.4142 21 12 21C11.5858 21 11.25 20.6642 11.25 20.25L11.25 3.75C11.25 3.33579 11.5858 3 12 3Z"
      fill="#F7F7F7"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M19.2803 12.9697C19.5732 13.2626 19.5732 13.7374 19.2803 14.0303L12.5303 20.7803C12.2374 21.0732 11.7626 21.0732 11.4697 20.7803L4.71967 14.0303C4.42678 13.7374 4.42678 13.2626 4.71967 12.9697C5.01256 12.6768 5.48744 12.6768 5.78033 12.9697L12 19.1893L18.2197 12.9697C18.5126 12.6768 18.9874 12.6768 19.2803 12.9697Z"
      fill="#F7F7F7"
    />
  </svg>
);

export default DownIcon;
