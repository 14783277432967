const RightIcon = (props: any) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="17"
    height="17"
    viewBox="0 0 17 17"
    fill="none"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0.789061 8.5C0.789062 8.16863 1.08754 7.9 1.45573 7.9L16.1224 7.9C16.4906 7.9 16.7891 8.16863 16.7891 8.5C16.7891 8.83137 16.4906 9.1 16.1224 9.1L1.45573 9.1C1.08754 9.1 0.789061 8.83137 0.789061 8.5Z"
      fill="#D9D9D9"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.65099 2.67574C9.91134 2.44142 10.3335 2.44142 10.5938 2.67574L16.5938 8.07574C16.8542 8.31005 16.8542 8.68995 16.5938 8.92427L10.5938 14.3243C10.3335 14.5586 9.91134 14.5586 9.65099 14.3243C9.39064 14.09 9.39064 13.7101 9.65099 13.4757L15.1796 8.5L9.65099 3.52427C9.39064 3.28995 9.39064 2.91005 9.65099 2.67574Z"
      fill="#D9D9D9"
    />
  </svg>
);

export default RightIcon;
