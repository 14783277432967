import { faInstagram, faTiktok } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
import ExternalBlackIcon from "../icons/ExternalBlack";
import "./Footer.css";

const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer-container">
        <Link to="/">
          <img
            loading="lazy"
            src="logo.png"
            alt="Movember MindSets"
            className="small"
          />
        </Link>
        <div className="links">
          <Link to="about">About</Link>
          <Link to="contact">Contact</Link>
          <Link to="gyms">Gyms</Link>
        </div>
        <div className="support">
          <a href="https://www.movember.com/mens-health/mental-health" target="_blank" rel="noopener noreferrer">
            <div className="button">
              <span>
                Get more tools at Movember.com&nbsp;
                <ExternalBlackIcon />
              </span>
            </div>
          </a>
        </div>
        <div className="join">
          <div className="socials">
            <a href="http://instagram.com/Movember" target="_blank" rel="noopener noreferrer">
              <FontAwesomeIcon icon={faInstagram} inverse size="xl" />
            </a>
            <a href="https://www.tiktok.com/@movember" target="_blank" rel="noopener noreferrer">
              <FontAwesomeIcon icon={faTiktok} inverse size="xl" />
            </a>
          </div>
          <div className="right">
            <h5>Join online</h5>
            <p>#MovemberMindSets</p>
          </div>
        </div>
      </div>
      <div className="copyright">&copy; 2024</div>
    </footer>
  );
};

export default Footer;
